import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data.js"
import "../../styles/teams.scss"
import flyer from "../../images/training-team-flyer.jpeg"
import Layout from "../../components/layout-teams"
import "../../styles/layout.scss"

const TrainingTeam = ({data}) => (
  <Layout data={data}>
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="Training Team | RF Dance"
        description="Non-performing latin dance team with Rhythmic Fusion. No experience required." 
        pathname="teams/training-team"/>
      <Hero 
        titleRed="Training"
        titleWhite="Team" />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          <TeamData
              name="RF Training Team"
              subheading="All levels"
              link="https://www.eventbrite.com/e/rf-training-team-april-august-tickets-56082686826"
              date="Ready to start?">
              <p>Rhythmic Fusion’s Training Team is perfect for those that want to train harder, but who aren’t interested in performing.</p>
              <p><a href="https://www.facebook.com/events/2013123478753552/" target="_blank" rel="noopener noreferrer">See our Facebook event</a></p>
          </TeamData>

          <div className="team-description-wrapper columns" style={({'marginTop':'4em'})}>
            <div className="column display--inline-block">
              <p>Want to join a team but not ready to perform? Join our non-performance team!</p>
              <p>Open call dates: April 7th, 14th, 21st &amp; 28th 2019<br />
              Season Runs every Sunday from April 7th through August 25th</p>
              <p><strong>We cover all the following:</strong></p>
              <div className=" team-description columns">
                <div className="column">
                    <ul style={({'fontSize':'0.9em'})}>
                      <li>Body movement</li>
                      <li>Transfer of weight</li>
                      <li>Hip movement/placement</li>
                      <li>Arm styling</li>
                      <li>Hair whips and head rolls</li>
                      <li>Foot detail</li>
                      <li>Drops</li>
                      <li>Musicality syncopation</li>
                      <li>Dips</li>
                      <li>Body manipulation and body rolls</li>
                      <li>Push-and-pull connection</li>
                    </ul>
                  </div>
                  <div className="column">
                    <ul style={({'fontSize':'0.9em'})}>
                      <li>Connection points</li>
                      <li>Posture</li>
                      <li>Spin control</li>
                      <li>Solo pivots &amp; partnering pivots</li>
                      <li>Swing stepping</li>
                      <li>Open &amp; closed position</li>
                      <li>Musical timing changes for leaders and followers</li>
                      <li><em>and so much more...</em></li>
                    </ul>
                  </div>
              </div>
              <div className="value-statement">
                <p>$100 monthly includes 1.5 hr hours of weekly training team every Sunday, <strong>PLUS:</strong></p>
                <ul>
                  <li>1hr Bachata Mondays Group Class</li>
                  <li>1hr Salsa Wednesdays Group Class</li>
                  <li>1hr Bachata Thursdays Group Class</li>
                  <li>2hr Monthly Workshop</li>
                </ul>
              </div>
            </div>
            <div className="column">
              <img src={flyer} alt="RF Dance Training Team Auditions" style={({'width':'100%','maxWidth':'500px'})}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TrainingTeam